import React, { useState, useEffect } from "react";

import { EuiBasicTable, EuiHealth } from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";
import ClassifyAisleButton from "../components/ClassifyAisleButton";

function Errors({ errors }) {
  const listErrorItem = errors.map((e, i) => <li key={`error-${i}`}> {e}</li>);
  return <ul>{listErrorItem}</ul>;
}

function ParsedIngredientLines({ ingredientLines }) {
  const url = "https://ilp.wellifize.app/api/v2/nutrients_ingredient_lines/";
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientLines: ingredientLines,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          if (result.results) {
            setItems(result.results);
          } else {
            setError({ message: result.details });
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [ingredientLines]);

  const columns = [
    {
      field: "ingredientLine",
      name: "Ingredient Line",
    },
    {
      //field: "name",
      name: "Quantity",
      render: (i) => {
        return `${i.qty} = ${Number.parseFloat(i.qty_numeric).toFixed(2)}`;
      },
    },
    {
      field: "unit",
      name: "Unit",
    },
    {
      field: "name",
      name: "Ingredient",
      render: (ing) => {
        return ing;
      },
    },
    {
      field: "meta",
      name: "USDA Match",
      render: (meta) => {
        return meta ? meta.usda_name : "";
      },
    },
    {
      field: "meta",
      name: "Grams",
      render: (meta) => {
        return meta ? Math.round(meta.grams) : "-";
      },
    },
    {
      field: "meta",
      name: "ENERC_KCAL",
      render: (meta) => {
        if (meta) {
          if (meta.nutrients) {
            let enercKcal = meta.nutrients.ENERC_KCAL;
            let grams = Math.round(meta.grams);
            return Math.round(grams * enercKcal.amount);
          }
        }
        return "x";
      },
    },
    {
      field: "aisle",
      name: "Aisle",
      render: (aisle) => {
        if (aisle) {
          return aisle[0];
        }
        return "x";
      },
    },
    {
      field: "name",
      name: "GPT-3",
      render: (ing) => {
        return <ClassifyAisleButton ingredient={ing} />;
      },
    },
    {
      field: "errors",
      name: "Parsed Correctly",
      dataType: "boolean",
      render: (errors) => {
        const color = !errors.length ? "success" : "danger";
        return (
          <EuiHealth color={color}>
            <Errors errors={errors} />
          </EuiHealth>
        );
      },
    },
  ];

  const getRowProps = () => {
    const databaseId = htmlIdGenerator("row")();
    return {
      "data-test-subj": databaseId,
      className: "customRowClass",
      //onClick: () => console.log(`Clicked row ${databaseId}`),
    };
  };

  const getCellProps = () => {
    return {
      className: "customCellClass",
      "data-test-subj": htmlIdGenerator("cell")(),
      textOnly: true,
    };
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <EuiBasicTable
        items={items}
        rowHeader="ingredientLines"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
      />
    );
  }
}

export default ParsedIngredientLines;
